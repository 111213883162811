







































import { Component, Prop, Ref } from "vue-property-decorator";
import ScrollIndicator from "@/components/tables/ScrollIndicator.vue";
import { TranslateResult } from "vue-i18n";
import TranslatableComponent from "@/lib/mixins/translateable";
import { NestedKeyOf } from "@/components/tables/types";

export interface TableHeaders<T extends Object> {
  property: NestedKeyOf<T>;
  name: string | TranslateResult;
  class?: string | null;
}

@Component({
  components: { ScrollIndicator },
})
export default class DefaultTable<
  T extends Object
> extends TranslatableComponent {
  @Prop()
  readonly data!: T[];

  @Prop()
  private headers!: TableHeaders<T>[];

  @Prop()
  private index!: keyof T;
}
